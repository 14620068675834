@import '../tdi/css/lib';

.termRef {
  border-bottom: dotted 1px;
}

#tdi--tooltip {
  background: #001a73;

  &:after {
    border-top: 10px solid #001a73;
  }
  &.top:after {
    border-bottom: 10px solid #001a73;
  }
}

.term {
  margin-bottom: 24pt;
}
.term .name {
  font-size: 12pt;
}
.term .definition {
  font-size: 10pt;
}


a {cursor:pointer;}
strong {font-weight:bold;}
em {font-style:italic;}
sup, sub {position: relative;top: -0.4em;vertical-align: baseline;}
sub { top: 0.4em; }
tr {height:20px;}
section img {max-width:100%;height:auto;}

.marginbottom- {
  &none { margin-bottom:0; }
}
span.highlight {
  background-color:#d6ecff;
}

table.standard,
table.table {
  // horizontal alignment
 // th, td {text-align: right;}
 // th:first-child, td:first-child {text-align: left;}
  th.notes, td.notes {text-align: center;}
  // explicit classes overrides defaults
  th, td {
    &.text-center {text-align: center;}
    &.text-right {text-align: right;}
    &.text-left {text-align: left;}
    &.text-justify {text-align: justify;}
  }

  // vertical alignment
  th {vertical-align: bottom;}
  td {vertical-align: top;}
  // explicit classes overrides defaults
  th, td {
    &.top {vertical-align: top;}
    &.bottom {vertical-align: bottom;}
    &.middle {vertical-align: middle;}
  }
}

@each $list-style in (
  none, square, circle, disc,                                                       // ul list styles
  decimal, decimal-leading-zero, lower-alpha, upper-alpha, lower-roman, upper-roman // ol list-styles
) {
  .list-style-#{$list-style} {
    list-style: $list-style;
  }
}

.search {
  .search-result {
    padding-bottom:10px;
    h3 {
      margin-bottom:5px;
    }
    .search-result-snippet {
      .snippet-searchresult-highlight {
        font-weight: bold;
      }
    }
  }
}

.kpi-item-icon img {
  height: 1.5rem;
  width: 1.5rem;
  fill: #001a73; 
}
@media (min-width: 992px) {
    .kpi-item-icon img {
      height: 3rem;
      width: 3rem; 
    } 
}

img.highlight-item-icon {
  height: fit-content;
}      

.show-div {
  display: block;
}

.hide-div {
  display: none;
}

.subfont {
  font-weight: normal;
}

/* Risk Table */

.postnl-risktable-greybg {
  background-color: #f4f5f8;
  border-right: 1px solid #d5dae3;
}

.postnl-risktable-head {
  font-family: 'Hanken Grotesk', sans-serif;
  color: #acb6c6;
  padding-top: 3em;
}

.postnl-risktable-contenthead-row {
  border-bottom: 1px solid #d5dae3;
}

.postnl-risktable-contenthead {
  font-family: 'Hanken Grotesk', sans-serif;
  padding-top: 0.5em;
  color: #acb6c6;
  margin-bottom: 0.5em;
}

.postnl-risktable-arrowleft {
  max-height: 0.9rem;
  width: 0.9rem;
  margin-right: 0.5em;
}

.postnl-risktable-arrowright {
  max-height: 0.9rem;
  width: 0.9rem;
  margin-left: 0.5em;
}

.postnl-risktable-classification {
  border-right: 1px solid #d5dae3;
  border-bottom: none;
  padding: 2px;
}

.postnl-risktable-row {
  border-bottom: 1px solid #d5dae3;
}

.postnl-risktable-row-first {
  border-bottom: 1px solid #d5dae3;
}

.postnl-risktable-firstitemrow {
  border-top: unset;
}

.postnl-risktable-row > .postnl-risktable-greybg {
  padding: 1em 0.8em;
}

.postnl-risktable-row  > .postnl-risktable-greybg > span {
  font-family: 'Hanken Grotesk', sans-serif;
  color: #f56900;
  font-size: 1.3rem;
}

.postnl-risktable-row  > .postnl-risktable-greybg > p {
  font-family: 'Hanken Grotesk', sans-serif;
  color: #000000;
  font-size: 0.9rem;
}

.postnl-risktable-fillables {
  padding: 2px;
  //border-right: 1px solid #d5dae3;
  --bs-gutter-x: 0;
}

.postnl-risktable-fillables > img {
  max-width: 30%;
  //margin-bottom: 10em;
}

.postnl-showmobile {
  display: none !important;
}

@media only screen and (max-width: 768px) {
  .postnl-showmobile {
    display: flex;
  }
}

div.image-layer {
  position: relative;
  
  .buttom-zoomin {
    position: absolute;
    bottom: 10px;
    right: 50px;
  }
  .buttom-download {
    position: absolute;
    bottom: 10px;
    right: 10px;
  }
}